@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Importing Google Font */

/* Global styles */
body {
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fbede2;
}

.nav-links a {
  text-decoration: none;
  color: black;
  margin: 0 10px;
  position: relative;
}

.nav-links a.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: black;
}

.nav-links a:hover {
  text-decoration: underline;
}

input::placeholder {
  color: #000000;
}

.body {
    color: black;
    text-decoration: none;
    position: relative;
    font-family: 'Poppins';
    font-size: 5rem;
  }
/* src/components/ProductGrid.css */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
    margin-top: 50px;
  }
  
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .product-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .product-name {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .product-description {
    font-size: 14px;
    color: #666;
  }
  
/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    opacity: 1;
    transition: opacity 1s ease-in-out; /* Transition for fading */
    background-color: #000000; /* Black background */
  }
  
  .loading-container.fade-out {
    opacity: 0;
  }
  
  .loading-image,
  .loading-logo {
    max-width: 20%;
    max-height: 20%;
  }
  
  .loading-image {
    margin-bottom: 20px; /* Space between the images */
  }
  
.product-detail {
  padding: 20px;
  max-width: 900px;
  margin: auto;
}

.back-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.product-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.detail-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-details {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1;
}

.product-details h1 {
  margin-top: 0;
}

.product-details p {
  margin: 10px 0;
}

.purchase-link {
  display: inline-block;
  margin-top: 15px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.purchase-link:hover {
  background-color: #218838;
}

@media (min-width: 768px) {
  .product-content {
    flex-direction: row;
    text-align: left;
  }

  .detail-image {
    margin-right: 20px;
    margin-bottom: 0;
    flex-shrink: 0;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


