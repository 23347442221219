.product-detail {
  padding: 20px;
  max-width: 900px;
  margin: auto;
}

.back-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.product-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.detail-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-details {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.product-details h1 {
  margin-top: 0;
}

.product-details p {
  margin: 10px 0;
}

.purchase-link {
  display: inline-block;
  margin-top: 15px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.purchase-link:hover {
  background-color: #218838;
}

@media (min-width: 768px) {
  .product-content {
    flex-direction: row;
    text-align: left;
  }

  .detail-image {
    margin-right: 20px;
    margin-bottom: 0;
    flex-shrink: 0;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

