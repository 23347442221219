@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.nav-links a {
  text-decoration: none;
  color: black;
  margin: 0 10px;
  position: relative;
}

.nav-links a.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: black;
}

.nav-links a:hover {
  text-decoration: underline;
}

input::placeholder {
  color: #000000;
}
