/* src/components/ProductGrid.css */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    margin-top: 50px;
  }
  
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .product-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .product-name {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .product-description {
    font-size: 14px;
    color: #666;
  }
  